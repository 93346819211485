import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
// import { Link } from "react-router-dom";
// import filesFetcher from "../../Services/filesFetcher";

import LeftBtn from "../assets/images/latestwork-left-btn.png";
import RightBtn from "../assets/images/latestwork-right-btn.png";

import img1 from "../assets/images/lclc11.jpeg";
import img2 from "../assets/images/lclc2.jpeg";
import img3 from "../assets/images/lclc3.jpeg";
import img4 from "../assets/images/lclc4.jpeg";
import img5 from "../assets/images/lclc5.jpeg";
import img6 from "../assets/images/Engineer_licence.jpg";

import "./WindowsOurLatestWork.scss";

const sliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
  infinite: true,
  centerPadding: "5px",
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const listingImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6
];


export const WindowsOurListing = ({
  title,
  hideButton,
  subtitle,
  noTitle,
  noButton,
  handler,
  children
}) => {

  // const [listingImages, setListingImages] = useState(null);

  // useEffect(async () => {
  //   const listingImagesLoaded = await filesFetcher.fetchListingImages();
  //   const listingImages = listingImagesLoaded.map( item => {
	// 		return "https://agm-landing.s3.us-east-2.amazonaws.com/websites/listingImages/" + item.url;
	// 	});
  //   setListingImages(listingImages);
  // }, []);


  const additionalSliderSettings = {
    initialSlide: 0,
    autoplay: true,
    focusOnSelect: true,
  };
  const sliderRef = useRef();
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };
  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <section className="ourLatestWork">

      <div  className="sliderContainer">
        {/* <div className="arrowLeft" style={{ cursor: 'pointer' }} onClick={prevSlide}>
          <img src={LeftBtn} />
        </div> */}
     
            {listingImages ? listingImages.map((item, index, items) => (
              <div className="box" key={index} onClick={()=>{ 
                handler.updateState({ contentImage: items[index] }); 
                handler.index = index;
                handler.items = items;
              }}>
                <img style={{borderRadius: '0px', boxShadow: '1px 2px 8px rgba(0,0,0,0.3)', cursor: 'pointer', marginBottom: '20px', maxWidth: '80px', marginLeft: '10px', marginRight: '10px' }} src={item} />
              </div>
            )) : null}
         
        {/* <div className="arrowRight" style={{ cursor: 'pointer' }} onClick={nextSlide}>
          <img src={RightBtn} />
        </div> */}
      </div>

      {children}
      
    </section>
  );

  // return (
  //   <section className="ourLatestWork">

  //     <div className="sliderContainer">
  //       <div className="arrowLeft" style={{ cursor: 'pointer' }} onClick={prevSlide}>
  //         <img src={LeftBtn} />
  //       </div>
  //       <div className="testimonial-slider">
  //         <Slider
  //           {...sliderSettings}
  //           {...additionalSliderSettings}
  //           ref={sliderRef}
  //         >
  //           {listingImages ? listingImages.map((item, index, items) => (
  //             <div className="box" key={index} onClick={()=>{ 
  //               handler.updateState({ contentImage: items[index] }); 
  //               handler.index = index;
  //               handler.items = items;
  //             }}>
  //               <img style={{borderRadius: '0px'}} src={item} />
  //             </div>
  //           )) : null}
  //         </Slider>
  //       </div>
  //       <div className="arrowRight" style={{ cursor: 'pointer' }} onClick={nextSlide}>
  //         <img src={RightBtn} />
  //       </div>
  //     </div>

  //     {children}
      
  //   </section>
  // );
};
